import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {DotcenyOrganEntityModel} from "@app/doss/models/dotceny-organ-entity.model";

/**
 * Display name of occupation
 */
@Pipe({name: 'dotcenyOrganEntity'})
@Injectable({
  providedIn: 'root'
})
export class DotcenyOrganEntityPipe implements PipeTransform {

  transform(dotcenyOrganEntity: DotcenyOrganEntityModel): string {
    return !dotcenyOrganEntity ? "" : `${dotcenyOrganEntity.name}`;
  }
}
