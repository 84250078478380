import {Injectable, Pipe, PipeTransform} from "@angular/core";

/**
 * Display name of occupation
 */
@Pipe({name: 'predchoziUkon'})
@Injectable({
  providedIn: 'root'
})
export class PredchoziUkonPipe implements PipeTransform {

  transform(predchoziUkon: any): string {
    return !predchoziUkon ? "" : `${predchoziUkon.id}: ${predchoziUkon.nazev}`;
  }
}
